<template>
    <el-dialog title="Configure Leave Type"
               width="80%"
               top="1vh"
               :close-on-click-modal="false"
               :visible="true">
        <form method="POST" enctype="multipart/form-data" class="note-form">
            <div class="row">
                <div class="col">
                    <fg-input label="Name" v-model="value.name"></fg-input>
                </div>
                <div class="col">
                    <label>Slot Color</label>
                    <slider-picker v-model="color"></slider-picker>
<!--                    <el-color-picker v-model="value.bgcolor" class="d-block"></el-color-picker>-->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="mb-0 w-100">Controllers Eligible to Bid
                        <multiselect v-model="value.employee_type_ids"
                                     tag-placeholder=""
                                     placeholder="Leave blank for any employee"
                                     :close-on-select="false"
                                     label="name"
                                     :options="employeeTypes.map(type => type.id)"
                                     :custom-label="opt => employeeTypes.find(x => x.id === opt).type_name"
                                     :multiple="true">
                        </multiselect>
                    </label>
                </div>
                <div class="col">
                    <label class="mb-0 w-100">Crew(s) Eligible to Bid
                        <multiselect v-model="value.crew_ids"
                                     tag-placeholder="New tag"
                                     placeholder="Select crew(s)"
                                     :close-on-select="false"
                                     label="name"
                                     :options="crews.map(crew => crew.id)"
                                     :custom-label="opt => crews.find(x => x.id === opt).name"
                                     :multiple="true">
                        </multiselect>
                    </label>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-8 col-md-4">
                    <label class="mb-2 w-100">
                        Leave Slot is Pre-Approved
                    </label>

                    <el-switch v-model="value.is_preapproved"
                              active-text="ON"
                              :active-value="1"
                              :inactive-value="0"
                              color="bg-lightblue"
                              class="align-top"></el-switch>
                </div>
            </div>
        </form>
        <div slot="footer">
            <n-button round type="info" @click.native="$emit('cancel')">Cancel</n-button>
            <n-button round type="success" @click.native="save" :disabled="$store.getters['loading']">Save Leave Type
            </n-button>
        </div>
    </el-dialog>
</template>
<script>
    import {mapGetters} from "vuex";
    import {Dialog, Switch, Select, Option, ColorPicker} from "element-ui";
    import Multiselect from "vue-multiselect";
    import { Slider } from "vue-color";

    export default {
        name: 'leave-type-form',
        props: {
            value: Object,
            show: Boolean,
        },
        components: {
            [Dialog.name]: Dialog,
            [Switch.name]: Switch,
            [ColorPicker.name]: ColorPicker,
            [Select.name]: Select,
            [Option.name]: Option,
            Multiselect,
            'slider-picker': Slider,
        },
        data() {
            return {
                Type: {},
                visible: false,
                color: "#fff",
            }
        },
        methods: {
            save() {
                // Save the type
                if (this.Type.id === undefined) {
                    this.$emit('create', this.Type);
                } else {
                    this.$emit('update', this.Type);
                }
            },
        },
        computed: {
            ...mapGetters({
                area: 'area/area',
                employeeTypes: 'facility/employeeTypes',
                crews: 'area/crews',
            }),
        },
        created() {
                this.Type = this.value;
                this.color = this.value.bgcolor;
        },
        watch: {
            /** V-Model */
            value(n) {
                this.Type = n
            },
            Type(n) {
                this.$emit('input', n);
            },
            /** Color picker */
            color(n) {
                if (typeof n === "object")
                    this.Type.bgcolor = n.hex;
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
