<template>
  <div>
    <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="fas fa-cog cursor-pointer"/>
            </span>

      <el-dropdown-menu>
        <el-dropdown-item @click.native="showForm(true)">
          <i class="fas fa-plus"></i> Create New Leave Type
        </el-dropdown-item>

        <el-dropdown-item @click.native="showForm()" v-if="selectedType.id">
          <i class="fas fa-sliders-h"></i> Manage <b>{{ type.name }}</b> Leave Type Settings
        </el-dropdown-item>

        <el-dropdown-item v-if="selectedType.id" class="text-danger" @click.native="deleteType()">
          <i class="fas fa-trash"></i> Delete Type
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <leave-type-form v-model="Type"
                     v-if="showTypeForm"
                     @create="create"
                     @update="update"
                     @cancel="showTypeForm = false"></leave-type-form>
  </div>
</template>
<script>
import LeaveTypeForm from './LeaveTypeForm'
import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const LeaveTypeRepository = RepositoryFactory.get('leavetype')

export default {
  name: 'leave-type-crud',
  components: {
    LeaveTypeForm
  },
  props: {
    type: {},
    selectedType: [Boolean, Object]
  },
  data () {
    return {
      showTypeForm: false,
      Type: {}
    }
  },
  methods: {
    create (Type) {
      LeaveTypeRepository.create(Type)
        .then((response) => {
          this.showTypeForm = false
          this.$store.commit('leave/add', response.data)
          this.$emit('change-tab', 'add', response.data.id)
        })
    },
    update (Type) {
      LeaveTypeRepository.update(Type.id, Type)
        .then((response) => {
          this.showTypeForm = false
          this.$store.commit('leave/update', response.data)
        })
    },
    deleteType () {
      // Confirm before deleting
      this.$confirm('This will permanently delete the Leave Type and all displayed leave slots. Continue?', 'Warning', {
        type: 'warning'
      }).then(() => {
        LeaveTypeRepository.delete(this.type.id)
          .then(() => {
            this.showTypeForm = false
            this.$emit('change-tab', 'delete', this.type.id) // Change tab before deleting
            this.$store.commit('leave/remove', this.type)
          })
      }).catch(() => {
      })
    },
    init () {
      return {
        area_id: this.area.id,
        bgcolor: '#f00',
        crew_ids: [],
        employee_type_ids: [],
        name: '',
        order: this.types.length
      }
    },
    showForm (creating = false) {
      if (creating) {
        // Create a new type with a fresh instance
        this.Type = this.init()
      } else {
        // Lose the reactivity to edit this type
        this.Type = JSON.parse(JSON.stringify(this.type))
      }
      this.showTypeForm = true
    }

  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      area: 'area/area',
      types: 'leave/types'
    })
  }
}
</script>
<style></style>
