<template>
    <ul class="slots">
        <leave-slot v-for="(slot, i) in day.slots"
                    :key="i"
                    @bid="$emit('bid', $event)"
                    v-bind="{
                        selectable: isSelectable,
                        allowed: isAllowed,
                        leaveslot: slot,
                        day,
                        }" />
    </ul>
</template>

<script>
import LeaveSlot from './LeaveSlot'
import { mapGetters } from 'vuex'

export default {
  name: 'leave-slot-container',
  components: {
    LeaveSlot
  },
  props: {
    day: Object
  },
  data () {
    return {
    }
  },
  methods: {},
  computed: {
    ...mapGetters({
      bidder: 'bidder/active',
      bids: 'leave/bids',
      restrictions: 'leave/restrictions',
      weekBidsRemaining: 'leave/weekBidsRemaining',
      bidsRemaining: 'leave/bidsRemaining',
      daysAllowed: 'leave/daysAllowed',
      hoursRemaining: 'leave/hoursRemaining'

    }),
    leavebids () {
      return _.flatMap(this.day.leave, 'bids')
    },
    isDaySelected () {
      // If no bidder then ignore
      if (!this.bidder) return false

      // See if any bids for the current bidder
      return _.filter(this.leavebids, { bidder_init: this.bidder.init.toUpperCase() }).length > 0
    },
    isAllowed () {
      if (this.daysAllowed === null) return false

      return this.daysAllowed.includes(this.day.d)
    },
    isCrewAvailable () {
      if (!this.restrictions.crewLimit || !this.bidder) return true

      const count = this.day.crew_counts[this.day.schedule.crew_ID]
      return count && count < this.restrictions.crewLimit || !count
    },
    isNextAllowed () {
      if (this.daysAllowed === null) return true

      // Find allowed days remaining
      const allowed = _.filter(this.daysAllowed, d => _.findIndex(this.bids, b => b.day.d === d) === -1)

      return allowed[0] === this.day.d
    },
    isRestricted () {
      // Allow all days
      if (this.daysAllowed === null) { return false }

      // Restrict days
      return !this.isAllowed || !this.isNextAllowed
    },
    isSelectable () {
      return !this.isDaySelected &&
                        this.weekBidsRemaining > 0 &&
                        this.bidsRemaining > 0 &&
                        this.isNextAllowed &&
                        this.day.schedule &&
                        this.day.schedule.shift_length <= this.hoursRemaining &&
                        this.isCrewAvailable
    }
  }
}
</script>
