<template>
    <div id="leave" class="mt-2">
        <card v-if="isTestMode">
            <h6 class="text-danger">No Leave Slots Available for Viewing</h6>
            <b>Possible causes:</b><br>
            <ul class="list-unstyled m-0">
                <li class="list-item">Facility has not enabled bidding</li>
                <li class="list-item">Facility requires users to be logged-in to view leave slots</li>
            </ul>
        </card>

        <el-tabs v-if="!isTestMode"
                 type="border-card"
                 v-model="activeType"
                 ref="types">
            <el-tab-pane v-for="type in tabs" :key="type.id">
                <span slot="label">
                    <i class="fas fa-square" :style="`color: ${type.bgcolor}`"></i>
                    {{ type.name }}
                </span>

                <div class="row mb-2">
                    <div class="col">
                        <h6 class="mb-0 d-inline-block">
                            {{ viewYear }} Leave Year
                        </h6>
                        <el-popover v-if="selectedType"
                                    placement="bottom"
                                    title="Who can bid these slots?"
                                    width="400"
                                    class="text-left"
                                    trigger="hover">
                            <i slot="reference" class="fas fa-info-circle text-primary cursor-help ml-2"></i>

                            <ul class="list-group">
                                <li class="list-group-item">
                                    <b>Crews</b><br>
                                      <el-tag v-if="type.crew_ids.length === 0" size="small" type="primary" :key="999">Anyone</el-tag>
                                      <template v-else>
                                        <el-tag v-for="crew in type.crew_ids" :key="crew" size="small" type="primary">
                                            {{ _.find(crews, { id: crew }).name }}
                                        </el-tag>
                                      </template>
                                </li>
                                <li class="list-group-item">
                                    <b>Employee Types</b><br>
                                  <el-tag v-if="type.employee_type_ids.length === 0" size="small" type="primary" :key="999">Anyone</el-tag>
                                  <template v-else>
                                    <el-tag v-for="emp in type.employee_type_ids" :key="emp" size="small" type="primary">
                                        {{ _.find(employeeTypes, { id: emp }) ? _.find(employeeTypes, { id: emp }).type_name : '' }}
                                    </el-tag>
                                  </template>
                                </li>
                            </ul>
                        </el-popover>

                        <ul class="list-inline edit-link leave-icons">
                            <li class="list-inline-item">
                              <change-leave-year @change="loadCalendar()" />
                            </li>
                            <li class="list-inline-item" v-if="!viewingPastYear">
                                <leave-slot-modal v-if="is('arearep', area.id)"
                                                          :drag.sync="drag"
                                                          :editing.sync="editing"
                                                          v-bind="{
                                                      leaveTypes,
                                                      selectedType,
                                                  }"
                                                  @save-slots="updateSlots" />
                            </li>
                            <li class="list-inline-item">
                                <a href="#" @click.prevent="printCalendar()">
                                    <i class="fas fa-print"></i>
                                </a>
                            </li>
                            <li class="list-inline-item" v-if="!viewingPastYear">
                                <leave-type-crud :type="type"
                                                     v-if="is('arearep', area.id)"
                                                     v-bind="{
                                                    selectedType,
                                                 }"
                                                 @change-tab="changeTab" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="calendar" id="leave-container">
                    <table id="leave-table" class="table results" @mouseleave="hovered = null">
                        <leave-row v-for="(days, idx) in rowDays"
                                   :key="'row-' + idx"
                                   v-slot="{ isVisible }"
                                   v-bind="{
                                       days,
                                       idx,
                                       show: openRows,
                                       editing
                                   }"
                                   @click-row="toggleRow(idx)">
                            <leave-day v-for="day in days" :key="'day-' + day.day"
                                       v-bind="{
                                           days: createDays,
                                           day,
                                           show: isVisible,
                                           editing,
                                           leaveTypes,
                                           selectedType,
                                       }"
                                       :hover.sync="hovered"
                                       :drag.sync="drag" />
                        </leave-row>
                    </table>
                </div>
            </el-tab-pane>
        </el-tabs>
        <print element-id="leave-container"
               :title="title"
               :trigger="print"
               @done="printCalendarDone()"></print>
    </div>
</template>

<script>
/**
     * Trying to select full week at a time. Need to simplify the slots under slot type to remove the array from each slot type
     * Generate ID for the slot for each day, automatically bidding the first slot
     *
     */

import moment from 'moment'
import { mapGetters } from 'vuex'
import LeaveRow from './LeaveRow'
import LeaveDay from './LeaveDay'
import LeaveTypeCrud from './LeaveTypeCrud'
import LeaveSlotModal from './LeaveSlotModal'
import { TabPane, Tabs, Tag } from 'element-ui'
import { ChangeLeaveYear } from '@/components'
import { Print } from '@/components/stateless'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const LeaveRepository = RepositoryFactory.get('leave')

const mergeCrewCounts = (current, add) => {
  if (_.isEmpty(add)) return

  // Loop through each crew to add
  for (const [key, value] of Object.entries(add)) {
    if (current[key]) {
      current[key] += value
    } else {
      current[key] = value
    }
  }
}

export default {
  name: 'leave',
  components: {
    ChangeLeaveYear,
    LeaveRow,
    LeaveDay,
    LeaveTypeCrud,
    LeaveSlotModal,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Tag.name]: Tag,
    Print
  },
  data () {
    return {
      activeType: 0,
      calendar: [],
      editing: false,
      openRows: [],
      slots: {},
      hovered: null,
      drag: false,
      print: false
    }
  },
  methods: {
    changeTab (action, id) {
      const idx = _.findIndex(this.leaveTypes, { id })
      // const types = Object.keys(this.leaveTypes),
      //       idx = _.indexOf(types, `${id}`);
      if (action === 'delete') {
        this.activeType = String(this.leaveTypes[idx + 1] ? idx + 1 : idx - 1)
      } else if (action === 'add') {
        this.activeType = String(idx)
      }
    },
    fetchCalendar () {
      return LeaveRepository.getCalendar(this.viewYear)
        .then((response) => {
          this.calendar = response.data
        })
    },
    fetchSlots () {
      return LeaveRepository.getSlots(this.viewYear)
        .then((response) => {
          this.slots = response.data.length === undefined ? response.data : {}
        })
    },
    loadCalendar () {
      return Promise.all([
        this.fetchCalendar(),
        this.fetchSlots()
      ])
    },
    openAllRows () {
      this.openRows = []
      return new Promise(resolve => {
        // Create array of rows
        const rows = []
        for (let i = 0; i < this.rowDays.length; i++) {
          rows.push(i)
        }

        this.openRows.push(...rows)
        resolve()
      })
    },
    printCalendar () {
      this.openAllRows()
        .then(() => {
          this.print = true
        })
    },
    printCalendarDone () {
      this.openRows = []
      this.print = false
    },
    toggleRow (idx) {
      const i = this.openRows.indexOf(idx)
      if (i === -1) { this.openRows.push(idx) } else { this.openRows.splice(i, 1) }
    },
    updateSlots (changes) {
      _.forEach(changes, newSlot => {
        const editSlot = _.find(this.slots[newSlot.calendar_id], currentSlot => currentSlot.leave_type_id === newSlot.leave_type_id)

        // Already slots for the day so update them
        if (editSlot) {
          editSlot.available_count += newSlot.slots - editSlot.slots// * (newSlot.slots >= editSlot.slots ? 1 : -1);
          editSlot.slots = newSlot.slots
        } else {
          // See if other slot types exist on the day
          if (this.slots[newSlot.calendar_id]) {
            this.slots[newSlot.calendar_id].push(newSlot)
          } else {
            this.$set(this.slots, newSlot.calendar_id, [newSlot])
          }
        }
      })
    }
  },
  computed: {
    _ () {
      return _
    },
    ...mapGetters({
      area: 'area/area',
      facility: 'facility/facility',
      viewYear: 'facility/viewYear',
      viewingPastYear: 'facility/viewingPastYear',
      is: 'auth/is',
      employeeTypes: 'facility/employeeTypes',
      leaveTypes: 'leave/types',
      crews: 'area/crews',
      bidder: 'bidder/active',
      bidMode: 'bidder/bidMode'
    }),
    columns () {
      return this.screenWidth >= 1200 ? 28 : this.screenWidth < 1200 && this.screenWidth > 800 ? 14 : 10
    },
    /** Handle test mode for non-admins */
    isTestMode () {
      // Check if test mode is active
      if (!this.facility.test_mode) return false

      // Test mode active but allow admins through
      return !this.is('arearep', this.area.id)
    },
    createDays () {
      this.calendar.forEach(day => {
        day.$d = moment.utc(day.dt) // Add moment to each day

        // Add bidder details if bidding
        if (this.bidMode && !_.isEmpty(this.bidder.schedule)) {
          this.$set(day, 'schedule', JSON.parse(JSON.stringify(this.bidder.schedule[day.d])))
        } else {
          this.$set(day, 'schedule', null)
        }

        // Crew details
        day.crew_counts = {}

        // Loop through slots
        const _slots = []
        let hasFirstSlot = false

        _.forEach(this.leaveTypes, type => {
          // Only print selected leave type, if one selected
          if (this.selectedType.id && type.id !== this.selectedType.id) return

          // Make sure there are slots to create
          if (this.slots[day.d] === undefined) return

          // Create the slots
          const slotDay = _.find(this.slots[day.d], { leave_type_id: type.id })
          const totalSlots = slotDay ? Math.max(slotDay.slots, slotDay.bid_count) : 0

          // Add leave details
          day.leave = _.keyBy(this.slots[day.d], 'leave_type_id')

          // Update crew counts if slots exist for this type in this day
          if (slotDay) { mergeCrewCounts(day.crew_counts, slotDay.crew_counts) }

          // Create the leave slots
          for (let i = 0; i < totalSlots; i++) {
            // Clone the slot and add bids, if there are any
            const slot = {} // = JSON.parse(JSON.stringify(slot));
            slot.id = slotDay.calendar_id + '_' + slotDay.leave_type_id + '_' + i
            slot.bgcolor = type.bgcolor
            slot.bid = slotDay.bids[i]
            slot.deleted = i >= slotDay.slots // Slot no longer exists, but showing bids
            slot.isFirstAvailable = false
            slot.leaveTypeId = slotDay.leave_type_id
            slot.crewId = slotDay.bids[i] ? slotDay.bids[i].crew_id : null

            // See if bidder can bid this type
            slot.canBidderBid = this.bidMode &&
                                this.bidder &&
                                (type.employee_type_ids.length === 0 ||
                                type.employee_type_ids.includes(this.bidder.employee_type_id))

            // See if first available slot
            if (!hasFirstSlot && (i + 1) > slotDay.bid_count && slot.canBidderBid) {
              slot.isFirstAvailable = true
              hasFirstSlot = true
            }

            // Store the slot
            _slots.push(slot)
          }
        })
        hasFirstSlot = false
        this.$set(day, 'slots', _slots)
      })
      return this.calendar
    },
    rowDays () {
      // this.calendar.forEach(c => c.$d = this.$moment.utc(c.dt)); // Add moment to each day
      return _.chunk(this.createDays, this.columns)
    },
    selectedType () {
      return this.tabs[parseInt(this.activeType)]
      // if (this.activeType === "0") return null;
      // return parseInt(_.keys(this.tabs)[this.activeType]);
    },
    tabs () {
      return [{
        id: 0,
        name: 'All Slots',
        bgcolor: '#426A97',
        employee_type: '',
        crew_ids: [],
        employee_type_ids: [1]
      }].concat(this.leaveTypes)
    },
    title () {
      return `bidATC :: ${this.facility.name} :: ${this.area.name} :: ${this.selectedType.name}`
    }
  },
  created () {
    this.loadCalendar()// this.selectedType);
  }
}
</script>

<style>
    #leave .el-tabs__content {
        overflow: visible !important;
    }
    #leave .leave-icons {
        margin-top: -90px;
        margin-right: -10px;
    }
</style>
