<template>
    <li class="mx-auto"
        :class="{
            'deleted-slot': leaveslot.deleted,
            'taken': leaveslot.bid,
            'biddable': isSelectable,
            'selected': isSelected,
            'allowed': isAllowed,
        }"
        @click="bid"
        :style="`border-bottom: 4px solid ${leaveslot.bgcolor}`">

        <div class="slot-crew" v-if="leaveslot.bid">
            {{ leaveslot.bid.crew_tag }}
        </div>

        <span v-if="leaveslot.bid" >
            {{ checkPrivacy ? leaveslot.bid.bidder_init : '**' }}
        </span>
        <span v-else-if="isSelected">
            {{ bidder.init }}
        </span>
        <span v-else>
            &nbsp;
        </span>
    </li>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'leave-slot',
  components: {},
  props: {
    day: Object,
    leaveslot: Object,
    selectable: Boolean,
    allowed: Boolean
  },
  data () {
    return {}
  },
  methods: {
    bid () {
      // Allow deselect
      if ((this.isSelected && this.isInCurrentWeek) || this.isSelectable) {
        this.$emit('bid', this.leaveslot)
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapGetters({
      checkPrivacy: 'facility/checkPrivacy',
      bidder: 'bidder/active',
      leavebids: 'leave/bids',
      activeBidWeek: 'leave/activeBidWeek',
      crews: 'area/crews'
    }),
    isBid () {
      return !!this.leaveslot.bid
    },
    isInCurrentWeek () {
      return _.findIndex(this.activeBidWeek.bids, { id: this.leaveslot.id }) !== -1
    },
    isSelected () {
      return _.map(this.leavebids, 'id').includes(this.leaveslot.id)
    },
    isAllowed () {
      return this.isBiddable && this.allowed
    },
    isSelectable () {
      return this.isBiddable && this.selectable
    },
    isBiddable () {
      return this.leaveslot.canBidderBid && this.day.schedule.shift && !this.isBid && this.leaveslot.isFirstAvailable
    }
  }
}
</script>
