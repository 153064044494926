<template>
    <tbody>
        <tr class="subhead cursor-pointer" @click="$emit('click-row')">
            <td v-for="header in headers" :key="header.month" :colspan="header.span">
                {{ header.month | month(header.span) }}
            </td>
        </tr>
        <tr class="leave-row">
            <slot v-bind="{
                isVisible,
            }"></slot>
        </tr>
    </tbody>
</template>

<script>
export default {
  name: 'leave-row',
  props: {
    days: Array,
    idx: Number,
    show: Array
  },
  data () {
    return {
    }
  },
  methods: {},
  computed: {
    headers () {
      const headers = []
      const firstMonth = this.days[0].month_name
      const lastMonth = this.days[this.days.length - 1].month_name
      const counts = _.countBy(this.days, d => d.month_name)

      // Get first month
      headers.push({
        span: counts[firstMonth],
        month: this.days[0].month_name
      })

      // Get last month if it changes
      if (lastMonth !== firstMonth) {
        headers.push({
          span: counts[lastMonth],
          month: lastMonth
        })
      }

      return headers
    },
    isVisible () {
      return this.show.includes(this.idx)
    }
  },
  watch: {
    // show: {
    //     handler(n) {
    //         // Fetch slots the first time the row is viewed
    //         if (n.includes(this.idx) && _.isEmpty(this.slots)) {
    //             this.fetchLeaveBids();
    //         }
    //     },
    //     deep: true,
    // }
  },
  filters: {
    month (value, span) {
      return span < 3 ? value.substr(0, 3) : value
    }
  }
}
</script>

<style scoped>

</style>
