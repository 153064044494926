<template>
    <td class="day"
        :class="{
            weekend: day.is_weekend,
            hovered: d.isHovered,
            dragged: d.isDragged,
            'drag-hover': d.isDragHovered,
            'drag-anchor': d.isDragStart || d.isDragEnd,
            'bg-info': isBidder && isRDO,
            'holiday': day.holiday || (day.schedule && day.schedule.is_holiday_ilo),
            'holidayILO': day.schedule && day.schedule.is_holiday_ilo,
        }"
        @mouseenter="bidMode || !is('arearep', area.id) ? null : $emit('update:hover', day.$d)"
        @click="clickDay">
        <div class="day-header">
            {{ day.day }}
            <span class="float-right">
                {{ day.d | utcDisplay('dd') }}
            </span>
        </div>
        <div class="day-counts">
            <span v-if="day.schedule && !isRDO">
                {{ day.schedule.shift }} <small>({{ day.schedule.shift_length }})</small>
            </span>
            <span v-else-if="day.schedule && isRDO">
                RDO
            </span>
            <span v-else-if="!day.schedule">
                {{ count.available }}/{{ count.total || 0 }}
            </span>
        </div>
        <div class="day-content">
            <leave-slot-container v-if="show || (show && isBidder && !isRDO)"
                                  v-bind="{
                                    day,
                                    }"
                                  @bid="bidSlot"/>
        </div>
    </td>
</template>

<script>
import { mapGetters } from 'vuex'
import LeaveSlotContainer from './LeaveSlotContainer'

export default {
  name: 'leave-day',
  components: {
    LeaveSlotContainer
  },
  props: {
    days: Array,
    day: Object,
    show: Boolean,
    editing: Boolean,
    hover: Object,
    drag: [Boolean, Object],
    selected: Array,
    // leaveTypes: Array,
    selectedType: [Boolean, Object]
  },
  data () {
    return {
      // slots: this.day.slots,
    }
  },
  methods: {
    bidSlot (slot) {
      const payload = {
        days: this.days,
        day: this.day,
        slot: slot
      }

      // Submit the bids
      this.$store.dispatch('leave/bid', payload)
        .catch(e => {
          this.$store.commit('alerts/newMessage', { type: 'error', message: e })
        })
    },
    clickDay () {
      // Make sure editing allowed
      if (!this.editing) return false

      // See if drag exists
      if (!this.drag || this.drag.complete === true) {
        // Start new drag if none exists
        this.$emit('update:drag', {
          start: this.day,
          end: this.day,
          complete: false
        })
      } else {
        // Finish the drag
        this.$emit('update:drag', this.normalize(this.drag))
      }
    },
    normalize (range) {
      // Cancel if no range
      if (!range) return null

      // Make sure start is before end
      const start = range.start
      const end = this.day
      const isNormal = start.$d.isBefore(end.$d)
      return {
        start: isNormal ? start : end,
        end: isNormal ? end : start,
        complete: true
      }
    }
  },
  computed: {
    ...mapGetters({
      area: 'area/area',
      is: 'auth/is',
      restrictions: 'leave/restrictions',
      bidWeek: 'leave/activeBidWeek',
      bidder: 'bidder/active',
      bidMode: 'bidder/bidMode',
      weekBidsRemaining: 'leave/weekBidsRemaining'
    }),
    d () {
      const d = this.day.$d // Today Moment object
      const drag = this.drag // Object of days
      const hover = this.hover // Moment object

      const _base = {
        isHovered: d.isSame(hover)
      }

      // Allow dragging/selecting of days
      const _drag = this.editing ? {
        isDragActive: drag,
        isDragHovered: drag && drag.complete === false && (d.isBetween(drag.start.$d, hover, null, '[]') || d.isBetween(hover, drag.start.$d, null, '[]')),
        isDragged: drag && d.isBetween(drag.start.$d, drag.end.$d, null, '[]'),
        isDragStart: drag && d.isSame(drag.start.$d),
        isDragEnd: drag && d.isSame(drag.end.$d)
      } : {}

      return { ..._base, ..._drag }
    },
    count () {
      let available = 0
      let total = 0
      let taken = 0
      const deleted = 0

      // Verify dependencies
      if (!this.day.slots.length) { return { available, total, taken, deleted } }

      // Filter by leave type, if necessary
      if (this.selectedType.id) {
        taken = this.day.leave[this.selectedType.id].bid_count
        total = this.day.leave[this.selectedType.id].slots
        available = this.day.leave[this.selectedType.id].available_count
        // this.bids[this.selectedType] !== undefined ? this.bids[this.selectedType].length : 0;
      } else if (!this.selectedType.id) {
        // _.forEach(this.leaveTypes, type => {
        // let counts = this.day.slots[type.id];
        taken = _.sumBy(Object.values(this.day.leave), 'bid_count')// counts ? counts[0].bid_count : 0;
        total = this.day.slots.length// counts ? Math.max(counts[0].slots, counts[0].bid_count) : 0;
        available = total - taken
        // });
      }

      // Calculate slots
      return {
        available,
        total,
        taken,
        deleted: _.countBy(this.day.slots, s => s.deleted)
      }
    },
    isBidder () {
      return !_.isEmpty(this.bidder)
    },
    isRDO () {
      return this.day.schedule && this.day.schedule.is_RDO
    }
  }
}
</script>
