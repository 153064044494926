var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"day",class:{
        weekend: _vm.day.is_weekend,
        hovered: _vm.d.isHovered,
        dragged: _vm.d.isDragged,
        'drag-hover': _vm.d.isDragHovered,
        'drag-anchor': _vm.d.isDragStart || _vm.d.isDragEnd,
        'bg-info': _vm.isBidder && _vm.isRDO,
        'holiday': _vm.day.holiday || (_vm.day.schedule && _vm.day.schedule.is_holiday_ilo),
        'holidayILO': _vm.day.schedule && _vm.day.schedule.is_holiday_ilo,
    },on:{"mouseenter":function($event){_vm.bidMode || !_vm.is('arearep', _vm.area.id) ? null : _vm.$emit('update:hover', _vm.day.$d)},"click":_vm.clickDay}},[_c('div',{staticClass:"day-header"},[_vm._v(" "+_vm._s(_vm.day.day)+" "),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm._f("utcDisplay")(_vm.day.d,'dd'))+" ")])]),_c('div',{staticClass:"day-counts"},[(_vm.day.schedule && !_vm.isRDO)?_c('span',[_vm._v(" "+_vm._s(_vm.day.schedule.shift)+" "),_c('small',[_vm._v("("+_vm._s(_vm.day.schedule.shift_length)+")")])]):(_vm.day.schedule && _vm.isRDO)?_c('span',[_vm._v(" RDO ")]):(!_vm.day.schedule)?_c('span',[_vm._v(" "+_vm._s(_vm.count.available)+"/"+_vm._s(_vm.count.total || 0)+" ")]):_vm._e()]),_c('div',{staticClass:"day-content"},[(_vm.show || (_vm.show && _vm.isBidder && !_vm.isRDO))?_c('leave-slot-container',_vm._b({on:{"bid":_vm.bidSlot}},'leave-slot-container',{
                                day: _vm.day,
                                },false)):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }