<template>
    <span>
        <a href="#" v-if="!editing" class="m-0" @click.prevent="$emit('update:editing', true)">
            <i class="fas fa-edit"></i>
        </a>
        <drop-down v-else tag="div" position="right">
            <n-button round
                      type="success"
                      class="bid-button small m-0"
                      slot="title"
                      @click.native="$emit('update:editing', true)">
                <i class="fas fa-pencil-alt mr-1"></i> Edit Slots <i class="fas fa-caret-down ml-2" />
            </n-button>

            <a href="#" class="dropdown-item" v-if="drag" @click.prevent="visible = true">
                <i class="fas fa-lg fa-edit text-success"></i> Edit Selected Dates
            </a>
            <a href="#" class="dropdown-item" v-if="drag" @click.prevent="$emit('update:drag', false)">
                <i class="fas fa-lg fa-ban text-warning"></i> Clear Selection
            </a>
            <a href="#" class="dropdown-item" @click.prevent="$emit('update:editing', false)">
                <i class="fas fa-lg fa-window-close text-danger"></i> Done Editing
            </a>
        </drop-down>

        <el-dialog title="Manage Leave Slots"
                   width="350px"
                   top="1vh"
                   :visible.sync="visible">
            <form method="POST" enctype="multipart/form-data" class="note-form">
                <label>Select Leave Type</label>
                    <multiselect class="w-100 mx-auto"
                                 v-model="type"
                                 tag-placeholder=""
                                 placeholder="Select Leave Slot Type"
                                 label="name"
                                 :options="types"
                                 track-by="id"
                                 :allow-empty="false"
                                 select-label=""
                                 deselect-label=""/>

                <p class="m-0 mt-4">
                    Set the <strong>NEW</strong> number of slots available for
                    <strong>{{ dates }}</strong>.
                </p>

                <div class="w-100 text-center">
                    <div class="btn-group w-100">
                        <n-button type="danger" @click.native="slots > 0 ? slots-- : ''" :disabled="slots === 0">
                            <i class="fas fa-minus"></i>
                        </n-button>
                        <n-button type="primary" wide>
                            <strong>{{ slots }}</strong>
                        </n-button>
                        <n-button type="success" @click.native="slots++" :disabled="slots > 15">
                            <i class="fas fa-plus"></i>
                        </n-button>
                    </div>
                </div>
            </form>
            <div slot="footer">
                <n-button round type="info" @click.native="visible = false">Cancel</n-button>
                <n-button round type="success" @click.native="save" :disabled="$store.getters['loading']">Save Slots
            </n-button>
        </div>
        </el-dialog>
    </span>
</template>

<script>
import DropDown from '@/components/stateless/DropDown'
import { Dialog } from 'element-ui'
import Multiselect from 'vue-multiselect'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const LeaveRepository = RepositoryFactory.get('leave')

export default {
  name: 'leave-slot-modal',
  components: {
    DropDown,
    [Dialog.name]: Dialog,
    Multiselect
  },
  props: {
    editing: Boolean,
    drag: [Boolean, Object],
    leaveTypes: Array,
    selectedType: Object
  },
  data () {
    return {
      visible: false,
      slots: 0,
      type: null
    }
  },
  methods: {
    save () {
      // Create data for submission
      const data = {
        start: this.drag.start.$d.format('YYYY-MM-DD'),
        end: this.drag.end.$d.format('YYYY-MM-DD'),
        slots: this.slots,
        type: this.type.id
      }

      // Save the slots
      LeaveRepository.saveSlots(data)
        .then(response => {
          this.$emit('save-slots', response.data)
        })

      // Close the window (immediately)
      this.visible = false
    }
  },
  computed: {
    dates () {
      if (!this.drag.start) return false
      return this.$options.filters.dayRange(this.drag.start.$d, this.drag.end.$d)
    },
    types () {
      if (_.isEmpty(this.leaveTypes)) return false

      return _.map(this.leaveTypes, type => {
        return { id: type.id, name: type.name }
      })
    }
  },
  watch: {
    visible () {
      this.type = _.find(this.leaveTypes, { id: this.selectedType.id })
    }
  }
}
</script>

<style scoped>
    /*span {*/
    /*    font-size: 0.8em;*/
    /*}*/
</style>
